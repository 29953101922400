import request from "@/api/request";

// 获取 社区课堂 类目
export function getCommunityClassroomClass() {
  return request({
    url: "/api/thinkTank/communityClassroom/class",
    method: "get",
  });
}

// 获取 社区课堂 文章列表
export function getCommunityClassroom(query) {
  return request({
    url: "/api/thinkTank/communityClassroom",
    method: "get",
    params: query,
  });
}

export function getCommunityClassroomHot(query) {
  return request({
    url: "/api/thinkTank/communityClassroom/hot",
    method: "get",
    params: query,
  });
}

export function getCommunityClassroomById(id) {
  return request({
    url: `/api/thinkTank/communityClassroom/detail/${id}`,
    method: "get",
  });
}

export function updateCommunityClassroomDownloads(id) {
  return request({
    url: `/api/thinkTank/communityClassroom/downloads/${id}`,
    method: "put",
  });
}


