<template>
	<div class="s-card-container">
		<div class="scc-search-btn">
			<a-input-search
				placeholder="请输入视频标题"
				enter-button="搜索"
				v-model="searchValue"
				@search="onSearch"
			/>
		</div>

		<a-card :extra="null" style="width: 100%">
			<div slot="title" style="overflow: auto">
				<span class="category-text">类目：</span>
				<a-checkable-tag
					v-for="itemChil of classIds"
					:key="itemChil._id"
					:checked="checkedList.indexOf(itemChil._id) > -1"
					@change="handleTabChange(itemChil._id, false)"
				>
					{{ itemChil.name }}
				</a-checkable-tag>
			</div>

			<div class="article-list">
				<a-row :gutter="16">
					<a-col
						class="gutter-row"
						:span="6"
						v-for="(fileItem, fileIndex) of fileList"
						:key="fileIndex"
					>
						<a-card
							:hoverable="true"
							@click="goPreviewHandle(fileItem)"
							style="margin-bottom: 8px;"
						>
							<img
								style="width: 100%; height: 150px"
								slot="cover"
								alt="example"
								:src="fileItem.cover"
							/>
							<a-card-meta>
								<template slot="title">
									<div class="s-ant-card-meta-title">
										<a-tooltip
											placement="topLeft"
											:title="fileItem.title"
										>
											{{ fileItem.title }}
										</a-tooltip>
									</div>
									<div class="s-ant-card-meta--sub-title">
										<a-tooltip
											placement="topLeft"
											:title="fileItem.title"
										>
											{{ fileItem.title }}
										</a-tooltip>
									</div>
								</template>
								<template slot="description">
									使用量：{{ fileItem.downloads }}
								</template>
							</a-card-meta>
						</a-card>
					</a-col>
				</a-row>
			</div>
			<div class="article-pagination">
				<a-pagination
					show-size-changer
					:default-current="1"
					:current="pagination.current"
					:pageSize="pagination.pageSize"
					:total="pagination.total"
					:pageSizeOptions="pagination.pageSizeOptions"
					@change="paginationChange"
					@showSizeChange="onShowSizeChange"
				/>
			</div>
		</a-card>
	</div>
</template>
<script>
import {
	getCommunityClassroomClass,
	getCommunityClassroom,
} from '@/api/thinkTank/communityClassroom'
export default {
	data() {
		return {
			searchValue: null,
			classIds: [],
			checkedList: [],
			fileList: [],
			pagination: {
				total: 0,
				pageSize: 10,
				current: 1,
				pageSizeOptions: ['10', '20', '50', '100'],
			},
		}
	},
	created() {
		getCommunityClassroomClass().then((res) => {
			this.classIds = res.list
			if (res.list && res.list.length > 0) {
				this.checkedList = [res.list[0]._id]
			}
			this.getTableData()
		})
	},
	methods: {
		getTableData(title) {
			const { pageSize, current } = this.pagination
			const params = {
				pageNum: current,
				pageSize: pageSize,
				title: this.searchValue,
				classIds: this.checkedList,
			}
			getCommunityClassroom(params).then((res) => {
				this.pagination.current = res.pageNum
				this.pagination.pageSize = res.pageSize
				this.pagination.total = res.total
				this.fileList = res.list
			})
		},
		onSearch(value) {
			this.getTableData()
		},
		goPreviewHandle(record) {
			this.$router.push(
				`/thinkTank/communityClassroom/preview/${record._id}`
			)
		},
		handleTabChange(_id, isTab) {
			if (this.checkedList.indexOf(_id) > -1) {
				this.checkedList.splice(this.checkedList.indexOf(_id), 1)
			} else {
				this.checkedList.push(_id)
			}
			this.getTableData()
		},
		onShowSizeChange(current, pageSize) {
			this.pagination.pageSize = pageSize
			this.getTableData()
		},
		paginationChange(page, pageSize) {
			this.pagination.pageSize = pageSize
			this.pagination.current = page
			this.getTableData()
		},
	},
}
</script>
<style lang="less" scope="this api replaced by slot-scope in 2.5.0+">
.s-card-container {
	.ant-tabs-tabpane {
		background: #fff;
		padding: 16px;
	}
	.scc-search-btn {
		width: 40%;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 16px;
	}
	.s-sub-tab-title {
		padding-bottom: 12px;
		border-bottom: 1px solid #eeeeee;
		.category-text {
			color: #333333;
		}
		.ant-tag {
			cursor: pointer;
		}
	}
	.article-list {
		.ant-card {
			width: 100%;
		}
		.ant-card-body {
			padding: 8px;
			.ant-card-meta-title {
				img {
					width: 24px;
					height: 24px;
					margin-right: 8px;
				}
				.s-ant-card-meta-title {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.s-ant-card-meta--sub-title {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					color: #666666;
					font-size: 14px;
				}
			}
			.ant-card-meta-description {
				text-align: right;
			}
		}
	}
	.article-pagination {
		margin-top: 26px;
		width: 50%;
		margin-left: auto;
		margin-right: auto;
		.ant-pagination {
			text-align: center;
		}
	}
}
</style>
